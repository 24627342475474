$content-width: 100%;
$nav-width: 100%;
$nav-width-mobile: 100%;
$nav-width-tablet: 150px;
$nav-width-desktop: 150px;
$nav-link-color: #4bbc29;
$color-primary: orange;
$color-secondary: blue;
$font-style: "Lucida Console", Monaco, monospace;
$font-color: #262626;
$link-color: #0d10a7;
$link-hover-color: $color-primary;
$heading-font: "Lucida Console", Monaco, monospace;
$heading-font-color: #262626;
$light: #E7EDF4;
$header:  #1D1425;

$tablet-width: 768px;
$desktop-width: 1024px;

*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
